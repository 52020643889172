<template>
    <div class="leftTab-contain">
        <div :class="item.active?'tab-item is-active':'tab-item'" v-for="item in tabList" :key="item.id"
             @click="goToLink(item)">
            <span class="tab-name">{{item.name}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "leftTab",
        data() {
            return {
                tabList: [
                    {
                        id: 0,
                        name: '课程体系',
                        active: false,
                        path: '/education/index'
                    },
                    {
                        id: 1,
                        name: '仿真平台',
                        active: false,
                        path: '/simulation/index'
                    },
                    {
                        id: 2,
                        name: '信息化平台',
                        active: false,
                        path: '/informatization/index'
                    },
                    {
                        id: 3,
                        name: '双师培训',
                        active: false,
                        path: '/doubleTrain/index'
                    },
                    {
                        id: 4,
                        name: '解决方案',
                        active: false,
                        path: '/solution/index'
                    },
                ],
                activeIndex: ''
            }
        },
        mounted() {
            this.activeIndex = localStorage.getItem('activeIndex');
            this.formatTabList(this.activeIndex);
        },
        methods: {
            formatTabList(val) {
                switch (val) {
                    case '/education/index':
                        this.tabList[0].active = true;
                        break;
                    case '/simulation/index':
                        this.tabList[1].active = true;
                        break;
                    case '/informatization/index':
                        this.tabList[2].active = true;
                        break;
                    case '/doubleTrain/index':
                        this.tabList[3].active = true;
                        break;
                    case '/solution/index':
                        this.tabList[4].active = true;
                        break;
                }
            },
            goToLink(item) {
                let routePath = this.$route.path;
                if (item.path == routePath){
                    return false;
                } else {
                    this.$router.push(item.path);
                    localStorage.setItem('activeIndex', item.path)
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .leftTab-contain {
        position: absolute;
        left: 0;
        top: 20px;

        .tab-item {
            width: 115px;
            height: 50px;
            background-color: rgba(0, 0, 0, .4);
            border-radius: 0px 6px 6px 0px;
            font-size: 18px;
            cursor: pointer;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .tab-name {
                color: #FFFFFF;
                font-weight: 300;
            }

            &.is-active {
                width: 125px;
                height: 50px;
                background: #F36F21;
                border-radius: 0px 6px 6px 0px;
            }
        }
    }
</style>