<template>
    <div class="education-contain">
        <el-scrollbar class="contain-box">
            <div class="top-box">
                <left-tab/>
                <div class="top-title">
                    <div class="title-box">
                        <div class="title-text">{{title}}</div>
                        <div class="title-info">{{description}}</div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="main-box-contain box1">
                    <div class="top-card">
                        <div class="top-card-contain">
                            <div class="card-title">三位一体培养双师队伍</div>
                            <div class="card-text">
                                为学校制订“专业带头人、骨干教师”人才培养计划，通过选拔、聘任具有行业影响力的专家、教授作为学校的专业带头人，选派行业企业专业人才和技术人才作为学校的兼职教师，各学校派遣专任教师到企业来实践，以培养“骨干教师”，加快各学校双师结构专业教学团队建设，为学校组建一支具有教学、研发和社会服务能力的双师结构合理的优秀教学团队。
                            </div>
                            <div class="img-box">
                                <img src="../../../assets/images/education/d-card1.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom-card">
                        <div class="bottom-card-contain">
                            <div class="box-title">培训特色</div>
                            <div class="card-text">
                                一课联合全国知名电商企业高管、电商专家等名师，为院校提供完善的电子商务专业师资团队培养建设体系。对电子商务专业教师团队进行专业知识与技能训练，通过加强双师教师团队的培养，提高教师团队的整体专业实践能力，提升专业教师教学经验水平，深度推进电子商务教育事业的发展，增强职业教育的吸引力，进一步提升职业教育服务经济社会发展的能力。
                            </div>
                            <div class="img-box">
                                <img src="../../../assets/images/education/d-card2.png" alt="">
                            </div>
                        </div>
                    </div>
                    <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import leftTab from "@/components/education/leftTab";
    import OfficialFooter from '@/components/OfficialFooter';

    export default {
        name: "DoubleTraning",
        components: {
            leftTab,
            OfficialFooter
        },
        data() {
            return {
                title: '双师培训',
                description: "为建设一支结构合理、兼具较高教学水平和实践能力、适应电子商务教育需要的师资队伍，以满足培养电子商务专业应用型人才的需要。",
            }
        },
        methods: {
            goToDetail() {
                this.$router.push('/education/test')
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-contain {
        height: 100%;
        width: 100%;

        .contain-box {
            height: 100%;
            width: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-box {
            width: 100%;
            height: 616px;
            position: relative;
            background-image: url("../../../assets/images/education/double_training.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #ffffff;

            .top-title {
                width: 1150px;
                margin: 0 auto;
                padding-top: 110px;

                .title-box {
                    padding: 0 350px 0 110px;

                    .title-text {
                        font-size: 45px;
                        color: #FFFFFF;
                    }

                    .title-info {
                        width: 640px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }

        .main-box {
            width: 100%;

            .main-box-contain {
                background-color: #fff;

                .box-title {
                    text-align: center;
                    font-size: 30px;
                    color: #333333;
                }

                &.box1 {
                    background-image: url("../../../assets/images/education/circle.png"), url("../../../assets/images/about/lift-hand.jpg");
                    background-repeat: repeat no-repeat;
                    background-size: cover, contain;
                    background-position: top, bottom;
                    position: relative;

                    .top-card {
                        width: 1150px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        position: absolute;
                        top: -280px;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);

                        .top-card-contain {
                            padding: 50px 88px;

                            .card-title {
                                text-align: center;
                                font-size: 30px;
                                color: #333333;
                            }

                            .card-text {
                                margin-top: 40px;
                                font-size: 16px;
                                color: #333333;
                                line-height: 48px;
                            }

                            .img-box {
                                text-align: center;
                                margin-top: 60px;
                            }

                        }
                    }


                    .bottom-card {
                        width: 1150px;
                        margin: 0 auto;
                        padding-top: 450px;
                        padding-bottom: 100px;

                        .bottom-card-contain {
                            padding: 50px 88px;

                            .card-text {
                                margin-top: 60px;
                                font-size: 16px;
                                color: #333333;
                                line-height: 48px;
                            }

                            .img-box {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>